





import {Component, Emit, Vue} from 'vue-property-decorator'
import {DrawModule} from '@/store'

@Component({})
export default class DeleteButtonComponent extends Vue {
  get canDelete(): boolean {
    return DrawModule.canDelete
  }

  @Emit('click')
  public handleDelete() {
    return true
  }
}
