





import {Component, Prop, Vue} from 'vue-property-decorator'
import {DrawStyle} from '@/components/fabric/DrawStyle'
import {DrawModule} from '@/store'

@Component({})
export default class DrawButtonComponent extends Vue {
  @Prop() drawMode!: DrawStyle

  public handleClick() {
    DrawModule.setDrawStyle(this.drawMode)
  }
}
