





import {Component, Emit, Vue} from 'vue-property-decorator'
import DrawButtonComponent from '@/components/drawings/buttons/DrawButton.vue'
import {DrawStyle} from '@/components/fabric/DrawStyle'

@Component({
  components: {
    'draw-button': DrawButtonComponent
  }
})
export default class DrawButtonOval extends Vue {
  drawModeOval: DrawStyle = DrawStyle.Oval
}
