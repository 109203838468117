import {ObjectDrawer} from '@/components/fabric/ObjectDrawer'
import {fabric} from 'fabric'
import {DrawStyle} from '@/components/fabric/DrawStyle'
import {IObjectOptions} from 'fabric/fabric-impl'

export class RectangleDrawer implements ObjectDrawer<fabric.Rect> {
  drawStyle: DrawStyle = DrawStyle.Rectangle

  private origX: number | undefined
  private origY: number | undefined

  make(
    x: number,
    y: number,
    options: IObjectOptions,
    width?: number | undefined,
    height?: number | undefined): Promise<fabric.Rect> {
    this.origX = x
    this.origY = y

    return Promise.resolve(new fabric.Rect({
      left: x,
      top: y,
      width: width,
      height: height,
      fill: 'transparent',
      ...options
    }))
  }

  resize(object: fabric.Rect, x: number, y: number): Promise<fabric.Object> {
    if (!this.origX || !this.origY) {
      return Promise.resolve(object)
    }

    object.set({
      originX: this.origX > x ? 'right' : 'left',
      originY: this.origY > y ? 'bottom' : 'top',
      width: Math.abs(this.origX - x),
      height: Math.abs(this.origY - y)
    }).setCoords()

    return Promise.resolve(object)
  }
}
