import {ObjectDrawer} from '@/components/fabric/ObjectDrawer'
import {fabric} from 'fabric'
import {DrawStyle} from '@/components/fabric/DrawStyle'
import {IObjectOptions} from 'fabric/fabric-impl'

export class PolylineDrawer implements ObjectDrawer<fabric.Polyline> {
  drawStyle: DrawStyle = DrawStyle.Polyline

  make(
    x: number,
    y: number,
    options: IObjectOptions): Promise<fabric.Polyline> {
    return Promise.resolve(new fabric.Polyline(
      [{x, y}],
      {
        ...options,
        fill: 'transparent',
        strokeWidth: 3,
        stroke: '#2196f3'
      }
    ))
  }

  resize(object: fabric.Polyline, x: number, y: number): Promise<fabric.Object> {
    if (!object) {
      return object
    }
    if (!object.points) {
      object.points = []
    }

    // Create and push a new Point for the Polyline
    object.points.push(new fabric.Point(x, y))
    const dim = object._calcDimensions()

    object.set({
      left: dim.left,
      top: dim.top,
      width: dim.width,
      height: dim.height,
      dirty: true,
      pathOffset: new fabric.Point(dim.left + dim.width / 2, dim.top + dim.height / 2)
    }).setCoords()

    return Promise.resolve(object)
  }
}
