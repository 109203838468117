







import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {DrawingEditor} from '@/components/fabric/DrawingEditor'
import {DrawModule, ProjectModule} from '@/store'
import {CursorMode} from '@/store/models/DrawModes'
import {emitter, Events} from '@/common/events'
import * as log from 'loglevel'
import DeleteButtonComponent from '@/components/drawings/buttons/DeleteButton.vue'

@Component({
  components: {
    'delete-button': DeleteButtonComponent
  }
})
export default class DrawingCanvasComponent extends Vue {
  @Prop() height!: number
  @Prop() width!: number
  @Ref('drawingCanvas') canvasRef!: HTMLCanvasElement
  @Ref('deleteBtn') deleteBtn!: Vue

  // private canvas: fabric.Canvas | undefined
  private editor: DrawingEditor | undefined

  mounted(): void {
    this.editor = new DrawingEditor(this.canvasRef, this.deleteBtn.$el as HTMLButtonElement, {
      publishCursorMode: (mode: CursorMode) => DrawModule.setCursorMode(mode),
      readCursorMode: () => DrawModule.cursorMode,
      readDrawStyle: () => DrawModule.drawStyle
    })

    emitter.on(Events.UPDATE_DRAW_STYLE, this.handlerUpdateDrawingMode)
    emitter.on(Events.SNAPSHOT_DRAW_SCREEN, this.handleSnapshotDrawScreen)
    emitter.on(Events.SNAPSHOT_RESTORE, this.handleSnapshotRestore)
    emitter.on(Events.SNAPSHOT_CLEAR, this.handleSnapshotClear)
  }

  beforeDestroy(): void {
    emitter.off(Events.UPDATE_DRAW_STYLE, this.handlerUpdateDrawingMode)
    emitter.off(Events.SNAPSHOT_DRAW_SCREEN, this.handleSnapshotDrawScreen)
    emitter.off(Events.SNAPSHOT_RESTORE, this.handleSnapshotRestore)
    emitter.off(Events.SNAPSHOT_CLEAR, this.handleSnapshotClear)
  }

  get cursorMode(): CursorMode {
    return DrawModule.cursorMode
  }

  get selectionActive(): boolean {
    return this.cursorMode === CursorMode.Select
  }

  public handlerUpdateDrawingMode(): void {
    this.editor?.updateDrawStyle()
  }

  public handleSnapshotDrawScreen(event?: { comment: string }): void {
    if (!this.editor) {
      return
    }
    const {snapshot, snapshotId, hasObjects, dataUrl} = this.editor.createSnapshot()

    log.debug('snapshot: ' + snapshotId)
    ProjectModule.storeSnapshot({
      snapshot,
      snapshotId,
      hasObjects,
      dataUrl,
      comment: event?.comment || '',
      canvasWidth: this.width,
      canvasHeight: this.height
    })
      .then(() => emitter.emit(Events.SNAPSHOT_DRAW_SCREEN_FINISHED, {finished: true}))
    this.editor.clearCanvas()
  }

  public handleSnapshotRestore(event: { id: string, snapshot: string } | undefined): void {
    log.debug('snapshot restore: ' + event?.id)
    if (!this.editor || !event || !event.id || !event.snapshot) {
      log.error('cannot restore snapshot: no editor or event found')
      return
    }

    this.editor.restoreSnapshot(event.snapshot, event.id)
  }

  public handleSnapshotClear(event: unknown | undefined): void {
    log.debug('clearing canvas')
    this.editor?.clearCanvas()
  }

  public deleteSelectedObject(): void {
    // TODO: api call
    this.editor?.deleteSelected()
  }
}
