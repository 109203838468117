/* eslint-disable no-tabs */
import {fabric} from 'fabric'
import {ObjectDrawer} from '@/components/fabric/ObjectDrawer'
import {LineDrawer} from '@/components/fabric/LineDrawer'
import {DrawStyle} from '@/components/fabric/DrawStyle'
import {RectangleDrawer} from '@/components/fabric/RectangleDrawer'
import {OvalDrawer} from '@/components/fabric/OvalDrawer'
import {PolylineDrawer} from '@/components/fabric/PolylineDrawer'
import {CursorMode} from '@/store/models/DrawModes'
import log from 'loglevel'
import {v4} from 'uuid'
import {IEvent} from 'fabric/fabric-impl'

export interface DrawHandlers {
  publishCursorMode: (mode: CursorMode) => void
  readCursorMode: () => CursorMode
  readDrawStyle: () => DrawStyle,
}

export class DrawingEditor {
  canvas: fabric.Canvas
  readonly drawerOptions: fabric.IObjectOptions
  private _drawer: ObjectDrawer<fabric.Object> | undefined
  private object: fabric.Object | undefined
  private readonly drawers: Array<ObjectDrawer<any>>
  private snapshotId: string | undefined

  private isDown = false

  constructor(private readonly selector: HTMLCanvasElement,
              private readonly deleteBtn: HTMLElement,
              private readonly handlers: DrawHandlers) {
    const brushSize = 10
    const brushColor = 'hotpink'
    this.canvas = new fabric.Canvas(selector, {
      selection: false,
      freeDrawingCursor: `url(${this.getDrawCursor(brushSize, brushColor)}) ${brushSize / 2} ${brushSize / 2}, crosshair`,
      isDrawingMode: true
    })
    this.canvas.freeDrawingBrush.color = brushColor
    this.canvas.freeDrawingBrush.width = brushSize

    this.handlers.publishCursorMode(CursorMode.Draw)

    this.drawers = [
      new LineDrawer(),
      new RectangleDrawer(),
      new OvalDrawer(),
      new PolylineDrawer()
    ]

    this._drawer = undefined

    this.drawerOptions = {
      stroke: 'black',
      strokeWidth: 1,
      selectable: true,
      strokeUniform: true
    }

    this.initializeCanvasEvents()
  }

  public clearCanvas(): void {
    this.canvas.clear()
    this.snapshotId = undefined
  }

  public createSnapshot(): { snapshot: string, snapshotId: string, hasObjects: boolean, dataUrl: string } {
    const snapshot = this.canvas.toDatalessJSON(['id'])
    const dataUrl = this.canvas.toDataURL({quality: 0.2})
    const currentObjects = this.canvas.getObjects()
    const hasObjects = currentObjects.length > 0

    if (!this.snapshotId) {
      this.snapshotId = v4()
    }

    return {hasObjects, snapshot, snapshotId: this.snapshotId, dataUrl}
  }

  /**
   * https://stackoverflow.com/questions/21177934/reset-canvas-and-reload-with-json-in-fabric-js
   * @param snapshot
   */
  public restoreSnapshot(snapshot: string, snapshotId: string): any {
    this.canvas.clear()
    this.canvas.loadFromJSON(snapshot, this.canvas.renderAll.bind(this.canvas))
    this.snapshotId = snapshotId
  }

  public updateDrawStyle() {
    const mode = this.handlers.readDrawStyle()
    if (!mode) {
      this._drawer = undefined
      return
    }

    const newMode = this.drawers.find(d => d.drawStyle === mode)
    if (!newMode) {
      throw new Error(`Cannot set draw mode to ${mode}: mode does not exist`)
    }
    this._drawer = newMode
  }

  public deleteSelected(): void {
    const obj = this.canvas.getActiveObject()
    if (!obj) {
      return
    }

    this.canvas.remove(obj)
    this.canvas.renderAll()
  }

  private initializeCanvasEvents() {
    this.canvas.on('mouse:down', (o) => {
      const e = <MouseEvent>o.e

      const pointer = this.canvas.getPointer(o.e)
      this.mouseDown(pointer.x, pointer.y)
    })

    this.canvas.on('mouse:move', (o) => {
      const pointer = this.canvas.getPointer(o.e)
      this.mouseMove(pointer.x, pointer.y)
    })

    this.canvas.on('mouse:up', (o) => {
      log.debug('mouse:up')
      this.isDown = false
    })

    this.canvas.on('mouse:down', (o) => {
      log.debug('mouse:down')
    })

    // https://stackoverflow.com/questions/58407403/event-handler-for-object-selection-on-fabricjs-canvas
    this.canvas.on({
      'selection:created': this.selectionHandler.bind(this),
      'selection:updated': this.selectionHandler.bind(this)
    })

    this.canvas.on('selection:cleared', (o) => {
      log.debug('selection:cleared')
      this.handlers.publishCursorMode(CursorMode.Draw)
    })
  }

  private selectionHandler(o: IEvent) {
    log.debug('selection:create/updated')
    this.handlers.publishCursorMode(CursorMode.Select)
    this.object = o.target
    if (!this.object) {
      return
    }

    this.object.hasControls = false

    // add delete button on selected
    this.positionDeleteButton(this.object)
    this.object.on('moving', () => {
      this.positionDeleteButton(this.object)
    })
  }

  private positionDeleteButton(object: fabric.Object | undefined): void {
    if (!object) {
      return
    }

    const buttonWidth = 60 // px
    const left = object.left ? object.left : 0

    this.deleteBtn.style.left = `${left - buttonWidth}px`
    this.deleteBtn.style.top = `${object.top}px`
  }

  private async mouseDown(x: number, y: number): Promise<any> {
    this.isDown = true // The mouse is being clicked

    if (this.handlers.readCursorMode() !== CursorMode.Draw || !this._drawer) {
      return
    }

    // Create an object at the point (x,y)
    this.object = await this.make(x, y)

    // Add the object to the canvas
    this.canvas.add(this.object)

    // Renders all objects to the canvas
    this.canvas.renderAll()
  }

  private mouseMove(x: number, y: number): any {
    if (!this.isDown || !this.object || this.handlers.readCursorMode() !== CursorMode.Draw || !this._drawer) {
      return // If the user isn't holding the mouse button, do nothing
    }

    // Use the Resize method from the ObjectDrawer interface
    this._drawer.resize(this.object, x, y)
    this.canvas.renderAll()
  }

  // Method which allows any drawer to Promise their make() function
  private async make(x: number, y: number): Promise<fabric.Object> {
    const id = v4()
    if (!this._drawer) {
      throw new Error('Cannot create fabric object: no active drawer')
    }
    return await this._drawer.make(x, y, {...this.drawerOptions, id})
  }

  private getDrawCursor = (brushSize: number, brushColor: string) => {
    const circle = `
		<svg
			height="${brushSize}"
			fill="${brushColor}"
			viewBox="0 0 ${brushSize * 2} ${brushSize * 2}"
			width="${brushSize}"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="50%"
				cy="50%"
				r="${brushSize}"
			/>
		</svg>
	  `

    return `data:image/svg+xml;base64,${window.btoa(circle)}`
  }
}
