import {ObjectDrawer} from '@/components/fabric/ObjectDrawer'
import {fabric} from 'fabric'
import {DrawStyle} from '@/components/fabric/DrawStyle'

export class OvalDrawer implements ObjectDrawer<fabric.Ellipse> {
  private origX: number | undefined
  private origY: number | undefined

  drawStyle: DrawStyle = DrawStyle.Oval

  make(
    x: number,
    y: number,
    options: fabric.IObjectOptions,
    rx?: number,
    ry?: number): Promise<fabric.Ellipse> {
    this.origX = x
    this.origY = y

    return Promise.resolve(new fabric.Ellipse({
      left: x,
      top: y,
      rx: rx,
      ry: ry,
      fill: 'transparent',
      ...options
    }))
  }

  resize(object: fabric.Ellipse, x: number, y: number): Promise<fabric.Object> {
    if (!this.origX || !this.origY || !object || !object.left || !object.top) {
      return Promise.resolve(object)
    }
    object.set({
      originX: this.origX > x ? 'right' : 'left',
      originY: this.origY > y ? 'bottom' : 'top',
      rx: Math.abs(x - object.left) / 2,
      ry: Math.abs(y - object.top) / 2
    }).setCoords()

    return Promise.resolve(object)
  }
}
