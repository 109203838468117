import {ObjectDrawer} from '@/components/fabric/ObjectDrawer'
import {DrawStyle} from '@/components/fabric/DrawStyle'
import {fabric} from 'fabric'

export class LineDrawer implements ObjectDrawer<fabric.Line> {
  drawStyle: DrawStyle = DrawStyle.Line

  make(x: number,
    y: number,
    options: fabric.IObjectOptions,
    x2?: number,
    y2?: number): Promise<fabric.Line> {
    const points = [x, y]
    if (x2 !== undefined && y2 !== undefined) {
      points.concat([x2, y2])
    }
    return Promise.resolve(new fabric.Line(points, options))
  }

  resize(object: fabric.Line, x: number, y: number): Promise<fabric.Object> {
    object.set({
      x2: x,
      y2: y
    }).setCoords()

    return Promise.resolve<fabric.Line>(object)
  }
}
